import {
    Box,
    Flex,
    HStack,
    Link,
    IconButton,
    Stack,
    Image,
    useDisclosure,
    useColorModeValue,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

function scrollToTargetAdjusted(elementId){
    let element = document.getElementById(elementId);
    let headerOffset = 96;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}

function scrollToTop(){
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

function Links(props) {
    return (
        <>
            <Link
                px={2}
                py={1}
                rounded={'md'}
                _hover={{
                    textDecoration: 'none',
                    bg: useColorModeValue('gray.200', 'gray.700'),
                }}
                onClick={() => {
                    props.onClose();
                    scrollToTargetAdjusted('nos-services');
                }}
            >
                Nos services
            </Link>

            <Link
                px={2}
                py={1}
                rounded={'md'}
                _hover={{
                    textDecoration: 'none',
                    bg: useColorModeValue('gray.200', 'gray.700'),
                }}
                onClick={() => {
                    props.onClose();
                    scrollToTargetAdjusted('pourquoi-nous');
                }}
            >
                Pourquoi nous ?
            </Link>

            <Link
                px={2}
                py={1}
                rounded={'md'}
                _hover={{
                    textDecoration: 'none',
                    bg: useColorModeValue('gray.200', 'gray.700'),
                }}
                onClick={() => {
                    props.onClose();
                    scrollToTargetAdjusted('commencez-avec-nous')
                }}
            >
                Commencez avec nous !
            </Link>
        </>
    );
}

export default function Simple() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box bg={useColorModeValue('gray.100', 'gray.900')} w={'100%'}>
            <Flex h={24} alignItems={'center'} justifyContent={'space-between'} px={4}>
                <IconButton
                    size={'md'}
                    icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                    aria-label={'Open Menu'}
                    display={{ md: 'none' }}
                    onClick={isOpen ? onClose : onOpen}
                />
                <HStack spacing={8} alignItems={'center'}>
                    <Image src={`${process.env.PUBLIC_URL}/logo.png`}
                           alt="Logo"
                           h={'60px'}
                           _hover={{
                               textDecoration: 'none',
                               cursor: 'pointer'
                           }}
                           onClick={() => scrollToTop()}
                    />

                    <HStack
                        as={'nav'}
                        spacing={4}
                        display={{ base: 'none', md: 'flex' }}>
                        <Links onClose={onClose}/>
                    </HStack>
                </HStack>
                <Box w='40px' h='40px'/>
            </Flex>

            {isOpen ? (
                <Box pb={4} display={{ md: 'none' }}>
                    <Stack as={'nav'} spacing={4}>
                        <Links onClose={onClose}/>
                    </Stack>
                </Box>
            ) : null}
        </Box>
    );
}