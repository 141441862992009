import {
    Container,
    SimpleGrid,
    Divider,
    HStack,
    Flex,
    Heading,
    Text,
    Stack,
    Icon,
    Box,
    useColorModeValue,
} from '@chakra-ui/react';
import {FaSitemap} from 'react-icons/fa';
import {AiOutlineCamera, AiFillWechat} from 'react-icons/ai';
import {HiOutlinePresentationChartLine} from 'react-icons/hi';
import {MdOutlinePriceChange, MdOutlineReportProblem, MdOutlineSupportAgent} from 'react-icons/md';
import {GiBroom, GiGrass} from 'react-icons/gi';
import {TbToiletPaper} from 'react-icons/tb';

const Feature = ({title, textAndIcons, iconBg}) => {
    return (
        <Stack>
            <HStack justify={'center'}>
                <div>
                    <Text fontWeight={600} fontSize={'xl'}>{title}</Text>
                </div>
            </HStack>
            <Divider/>

            {
                textAndIcons.map((textAndIcon) =>
                    <Stack>
                        <HStack flexDirection={'row'} align={'center'}>
                            <Flex
                                w={8}
                                h={8}
                                align={'center'}
                                justify={'center'}
                                rounded={'full'}
                                shrink={0}
                                bg={iconBg}>
                                {textAndIcon[1]}
                            </Flex>
                            <Text color={'gray.500'} fontSize={'medium'}>
                                {textAndIcon[0]}
                            </Text>
                        </HStack>

                        <Divider/>
                    </Stack>
                )
            }
        </Stack>

    );
};

export default function OurServices() {
    return (
        <Box p={4}>
            <Heading id="nos-services">
                Nos services
            </Heading>
            <Container maxW={'9xl'} py={10}>
                <Stack spacing={4}>
                    <SimpleGrid columns={{ sm: 1, md: 2}} spacing={10} alignItems={'flex-start'}>
                        <Feature
                            iconBg={useColorModeValue('orange.100', 'yellow.900')}
                            title={'Optimisation des revenus'}
                            textAndIcons={[
                                ['Mise en marché stratégique', <Icon as={HiOutlinePresentationChartLine} color={'orange.500'} w={5} h={5}/>],
                                ['Affichage sur plusieurs plateformes', <Icon as={FaSitemap} color={'orange.500'} w={5} h={5}/>],
                                ["Utilisation d'un logiciel de gestion dynamique des prix", <Icon as={MdOutlinePriceChange} color={'orange.500'} w={5} h={5}/>],
                                ['Photos professionnelles', <Icon as={AiOutlineCamera} color={'orange.500'} w={5} h={5}/>]
                            ]}
                        />
                        <Feature
                            iconBg={useColorModeValue('orange.100', 'green.900')}
                            title={'Gestion de logement'}
                            textAndIcons={[
                                ['Communications clients', <Icon as={AiFillWechat} color={'orange.500'} w={5} h={5}/>],
                                ['Communication avec les équipes de ménages', <Icon as={GiBroom} color={'orange.500'} w={5} h={5}/>],
                                ['Entretien de base', <Icon as={GiGrass} color={'orange.500'} w={5} h={5}/>],
                                ['Gestion des problèmes au chalet', <Icon as={MdOutlineReportProblem} color={'orange.500'} w={5} h={5}/>],
                                ['Gestion des produits de base', <Icon as={TbToiletPaper} color={'orange.500'} w={5} h={5}/>],
                                ['Support client 24/7', <Icon as={MdOutlineSupportAgent} color={'orange.500'} w={5} h={5}/>],
                            ]}
                        />
                    </SimpleGrid >
                </Stack>
            </Container>
        </Box>
    );
}