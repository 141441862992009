import {
    Box,
    Button,
    Container,
    Heading,
    Image,
    Stack,
    Text,
    VStack,
    Link,
    useBreakpointValue,
    SimpleGrid, OrderedList, ListItem, UnorderedList, Icon, Flex, background
} from '@chakra-ui/react'
import {
    MdPhone,
    MdEmail,
} from 'react-icons/md';
import { BsArrowDownCircle } from 'react-icons/bs';

export default function StartWithUs() {
    return (
        <Box p={4}>
            <Heading id="commencez-avec-nous">
                Commencez avec nous !
            </Heading>
            <Heading size={'lg'} textAlign="center" pb={4} py={6}>
                     Prochaines étapes
            </Heading>

            <OrderedList listStylePos={'inside'} maxW={'9xl'} flex={1} flexDirection={{base:'row', md:'row'}}>
                <SimpleGrid columns={{ sm: 1, md: 3}} spacing={10} flex={1} justifyContent={'center'}>
                    <div>
                        <ListItem>
                            <b>Cédulez un appel</b> de consultation dès maintenant pour discuter de :
                        </ListItem>

                        <UnorderedList>
                            <ListItem>
                                Vos besoins
                            </ListItem>
                            <ListItem>
                                Votre situation
                            </ListItem>
                            <ListItem>
                                Vos objectifs
                            </ListItem>
                            <ListItem>
                                Ce que nous pouvons vous apporter
                            </ListItem>
                        </UnorderedList>
                    </div>

                    <div>
                        <ListItem>
                            Si c'est <b>concluant</b>, on procède à :
                        </ListItem>

                        <UnorderedList>
                            <ListItem>
                                Déterminer un plan pour optimiser la propriété
                            </ListItem>
                            <ListItem>
                                Obtenir votre accord final
                            </ListItem>
                            <ListItem>
                                Lancer le projet
                            </ListItem>
                        </UnorderedList>
                    </div>

                    <div>
                        <ListItem>
                            <b>Collecte</b> d'informations et <b>création</b> des annonces :

                            <UnorderedList>
                                <ListItem>
                                    Remplissez un formulaire très simple
                                </ListItem>
                                <ListItem>
                                    On lance vos annonces
                                </ListItem>
                                <ListItem>
                                    Relaxez !
                                </ListItem>
                            </UnorderedList>
                        </ListItem>
                    </div>
                </SimpleGrid>
            </OrderedList>

            <VStack justify={'center'} pt={8} pb={4}>
                <Link href="https://calendly.com/yannicklaurent/30min" style={{ textDecoration: 'none' }}>
                    <Button size="md" color={'white'}
                            backgroundColor={'orange.400'}
                            _hover={{ bg: "orange.300" }}>
                        <Image src={`${process.env.PUBLIC_URL}/calendly.png`} boxSize={6} mr={2}/>
                        Réservez une plage horaire
                    </Button>
                </Link>
                <Link href="tel:15815784986" style={{ textDecoration: 'none' }}>
                    <Button
                        size="md"
                        height="40px"
                        width="200px"
                        color={'white'}
                        backgroundColor={'orange.400'}
                        _hover={{ bg: "orange.300" }}
                        leftIcon={<MdPhone color="#1970F1" size="20px" />}>
                        (581) 578-4986
                    </Button>
                </Link>
                <Link href="mailto: yannick@chaletsnordika.com" style={{ textDecoration: 'none' }}>
                    <Button
                        size="md"
                        height="40px"
                        width="320px"
                        color={'white'}
                        backgroundColor={'orange.400'}
                        _hover={{ bg: "orange.300" }}
                        leftIcon={<MdEmail color="#1970F1" size="20px" />}>
                        yannick@chaletsnordika.com
                    </Button>
                </Link>
            </VStack>
        </Box>
    );
}