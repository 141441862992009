import {Box, SimpleGrid, Icon, Text, Stack, Flex, Heading, Spacer} from '@chakra-ui/react';
import {AiFillStar} from "react-icons/ai";
import {FcApproval, FcDocument} from "react-icons/fc";
import {FaHandshake} from "react-icons/fa";

export default function SimpleThreeColumns() {
    return (
        <Box p={4}>
            <Heading id="pourquoi-nous">
                Pourquoi nous ?
            </Heading>
            <SimpleGrid columns={{ base: 1, sm: 1, md: 3 }} spacing={10} py={12} pl={{ base: 1, sm: 1, md: 20 }} pr={{ base: 1, sm: 1, md: 20 }}>
                <Stack>
                    <Flex
                        w={16}
                        h={16}
                        align={'center'}
                        justify={'center'}
                        color={'white'}
                        rounded={'full'}
                        bg={'gray.100'}
                        mb={1}>
                        <Icon as={FcDocument} w={10} h={10} />
                    </Flex>

                    <Text fontWeight={600} pb={1}>Tranquilité d'esprit</Text>

                    <Text color={'gray.600'} textAlign="justify" >Avec nous, votre seul travail est de consulter votre rapport mensuel qu'on vous envoie à chaque mois. Ce rapport reflète la performance du logement dans le mois passé. On s'occupe de donner aux propriétaires et aux clients un service hors pair.</Text>
                    <Spacer/>

                    <Flex justify={{base: 'center', '2xl' : 'flex-start'}}>
                        <Icon as={AiFillStar} w={5} h={5} color={'yellow'} />
                        <Icon as={AiFillStar} w={5} h={5} color={'yellow'} />
                        <Icon as={AiFillStar} w={5} h={5} color={'yellow'} />
                        <Icon as={AiFillStar} w={5} h={5} color={'yellow'} />
                        <Icon as={AiFillStar} w={5} h={5} color={'yellow'} />
                    </Flex>

                    <Spacer/>
                </Stack>

                <Stack>
                    <Flex
                        w={16}
                        h={16}
                        align={'center'}
                        justify={'center'}
                        color={'white'}
                        rounded={'full'}
                        bg={'gray.100'}
                        mb={1}>
                        <Icon as={FcApproval} w={10} h={10} />
                    </Flex>
                    <Text fontWeight={600} pb={1}>Satisfaction garantie - argent remis</Text>
                    <Text color={'gray.600'} textAlign="justify" >Récupérez 100% de votre investissement initial si vous n'êtes pas satisfait de notre travail dans les 30 premiers jours. Après 30 jours d'opérations, le propriétaire peut annuler le contrat avec 30 jours de préavis seulement.</Text>
                    <Spacer/>
                    <Text> L'annulation est <b>sans frais.</b></Text>
                    <Spacer/>
                </Stack>

                <Stack justify={'center'}>
                    <Flex
                        w={16}
                        h={16}
                        align={'center'}
                        alignItems={'center'}
                        justifyItems={'center'}
                        justify={'center'}
                        color={'orange.400'}
                        rounded={'full'}
                        bg={'gray.100'}
                        mb={1}>
                        <Icon as={FaHandshake} w={10} h={10} />
                    </Flex>
                    <Text fontWeight={600} pb={1}>Aucun risque pour votre annonce</Text>

                    <Text color={'gray.600'} textAlign="justify" display={"inline-block"}>Ajoutez-nous en tant que co-hôte sur votre annonce et si vous n'aimez pas nos services, vous gardez contrôle de votre annonce.</Text>
                    <Spacer/>
                </Stack>
            </SimpleGrid>
        </Box>
    );
}