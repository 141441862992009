import {ChakraProvider, Flex} from '@chakra-ui/react'
import Navbar from './Navbar';
import MissionStatement from "./MainSection/MissionStatement";
import WhyUs from "./MainSection/WhyUs";
import OurServices from "./MainSection/OurServices";
import {Box} from "@chakra-ui/react";
import SmallCentered from "./Footer";
import StartWithUs from "./MainSection/StartWithUs";
import theme from "./customTheme";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <div id={'app'}>
          <Flex as="header" position="fixed" w={'100%'} zIndex={999}>
              <Navbar/>
          </Flex>
          <Box id={'mainContent'} pt={'96px'} ml={{base: 0, xl:'5%',  '2xl' : '15%'}} mr={{base: 0, xl:'5%', '2xl' : '15%'}}>
              <MissionStatement/>
              <OurServices/>
              <WhyUs/>
              <StartWithUs/>
          </Box>
          <SmallCentered/>
      </div>
    </ChakraProvider>
  );
}

export default App;
