import {
    Container,
    Heading,
    Stack,
    Flex,
    Text,
    useBreakpointValue, SimpleGrid, StackDivider, useColorModeValue, Icon, Center,
} from '@chakra-ui/react';

import Typewriter from 'typewriter-effect';

const avc = {
    color: "yellow",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
};

export default function CallToActionWithIllustration() {
  return (
    <Container maxW={'9xl'} mb={'10'}>
      <Heading
          id={'home'}
          mt={10}
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
          align={'center'}
          lineHeight={'100%'}>
          Gardez la{' '}
          <Text as={'span'} color={'orange.400'}>
              paix d'esprit.
          </Text>
      </Heading>

      <Container maxW={'9xl'} my={10}>
          <SimpleGrid columns={{ sm: 1, md: 2}} spacing={{ base: 8, md: 14, lg: 0}}>
              <Stack order={{ base: 1, md: -1}} justify={'center'} align={'center'}>
                  <Text
                      color={'black'}
                      fontWeight={700}
                      lineHeight={1.2}
                      textAlign={'justify'}
                      display={'inline-block'}
                      fontSize={useBreakpointValue({ base: '1xl', sm: '2xl', md: '3xl' })}>
                      On vous aide à
                  </Text>
                  <Text
                      color={'black'}
                      fontWeight={700}
                      lineHeight={1.2}
                      textAlign={'justify'}
                      display={'inline-block'}
                      color={'orange.400'}
                      fontSize={useBreakpointValue({ base: '1xl', sm: '2xl', md: '3xl' })}>
                      <Typewriter
                          options={{
                              strings: ['optimiser vos revenus locatifs', 'déléguer les tâches de gestion'],
                              autoStart: true,
                              loop: true,
                              delay: 60,
                          }}
                      />
                  </Text>
              </Stack>

              <Stack>
                  <Flex
                      borderRadius='1%'
                      w={'full'}
                      h={{base: '40vw' ,md: '35vh'}}
                      backgroundImage={
                          `url(${process.env.PUBLIC_URL}/mission.jpeg)`
                      }
                      backgroundSize={'cover'}
                      backgroundPosition={'center center'}>
                  </Flex>
              </Stack>
          </SimpleGrid>
      </Container>
    </Container>
  );
};